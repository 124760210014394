import React from "react";
import { useState } from "react";

import iconimg from "./../assets/svg/coin.svg";
import { CopyIcon } from "../helper/icon";
function AddressCopy() {
  const [copied, setCopied] = useState(false);
  const textToCopy = "0x000000000000000000000000000000000000dead";

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  return (
    <>
      <div className="address-bg py-5 md:py-7   border-2 border-[#FF93BC] bg-[rgba(255,233,180,0.20)]">
        <div className="relative max-w-[1340px] mx-auto  sm:px-6 lg:px-8 2xl:px-10">
          <div className="max-w-[768px] mx-auto border-2 rounded-[20px] border-[#1A1A1A] bg-[#F95292] p-2 sm:p-3 flex flex-row justify-between items-center gap-2 lg:gap-4 whitespace-nowrap ">
            <div className="border-2 border-[#484848] rounded-lg sm:rounded-[20px] flex flex-col justify-center items-center w-10 sm:w-14 h-10 min-w-10 min-h-10 sm:h-14 md:w-[70px]  md:h-[70px]">
              <img src={iconimg} alt="icon" className="w-full" />
            </div>{" "}
            <p className="text-black text-sm sm:text-base md:text-xl text-ellipsis overflow-hidden  truncat">
              CA : 0x000000000000000000000000000000000000dead
            </p>
            <div className="border-2 border-[#484848] rounded-lg sm:rounded-[20px] flex flex-col justify-center items-center w-10 sm:w-14 h-10 min-w-10 min-h-10 sm:h-14 md:w-[70px] md:h-[70px] ">
              <button className="text-base" onClick={handleCopy}>
                {copied ? "Copied!" : <CopyIcon />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddressCopy;
