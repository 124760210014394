import React from "react";
import { headerLinks } from "../helper/Helper";
import { TeligramIcon, TwitterIcon } from "../helper/icon";

function Footer() {
  return (
    <>
      <div className="address-bg address-bg-2 py-5 md:py-7 mt-20 lg:mt-40   border-2 border-[#FF93BC] bg-[rgba(255,233,180,0.20)]">
        <div className="relative max-w-[1340px] px-4 mx-auto  sm:px-6 lg:px-8 2xl:px-10">
          <div className="flex flex-col sm:flex-row gap-4 justify-center sm:justify-between items-center ">
            <a
              href="/"
              className="logo text-white uppercase text-3xl sm:text-4xl lg:text-5xl xl:text-7xl"
            >
              Kogen
            </a>
            <div className="items-center gap-x-4 lg:gap-x-6 xl:gap-x-8 2xl:gap-x-11 flex">
              {headerLinks.map((item, index) => (
                <a
                  key={index}
                  href={item.link}
                  className="text-[#393939] text-base sm:text-lg lg:text-xl xl:text-2xl transition-all duration-300 ease-in-out hover:text-black"
                >
                  {item.name}
                </a>
              ))}
            </div>
            <div className="flex items-center gap-4 lg:gap-6 xl:gap-8">
              <a
                href={""}
                className="transition-all duration-300 ease-in-out border-2 border-[#484848] hover:border-black w-14 h-14 lg:w-[70px] lg:h-[70px]  rounded-[20px] bg-[#FF93BC] group flex flex-col justify-center items-center"
              >
                {" "}
                <TeligramIcon />
              </a>{" "}
              <a
                href={"https://x.com/kogencoin"}
                className="transition-all duration-300 ease-in-out border-2 border-[#484848] hover:border-black w-14 h-14 lg:w-[70px] lg:h-[70px]  rounded-[20px] bg-[#FF93BC] group flex flex-col justify-center items-center"
              >
                {" "}
                <TwitterIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
