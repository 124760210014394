import React from "react";
import { NextIcon, SettingIcon } from "../helper/icon";
import ethhimg from "./../assets/svg/ethh.svg";
import ethhimg2 from "./../assets/svg/swap-2.svg";
import swap from "./../assets/svg/swapimg.svg";
import teligrmaicon from "./../assets/svg/teligram.svg";
import leftdong from "./../assets/png/left-dog.png";
import rightdong from "./../assets/png/right-dog.png";
function CoinSwap() {
  return (
    <>
      <div className=" mt-12 lg:mt-20 xl:mt-24 2xl:mt-32">
        <div className="px-4 relative flex justify-center font-arial">
          <div
            data-aos="fade-left"
            data-aos-duration="2500"
            className="absolute bottom-0 left-0 max-w-[400px] "
          >
            <img src={leftdong} alt="dog" className="w-full" />
          </div>
          <div
            data-aos="fade-right"
            data-aos-duration="2500"
            className="absolute bottom-0 right-0 max-w-[400px] "
          >
            <img src={rightdong} alt="dog" className="w-full" />
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="500"
            className="max-w-[600px] mx-auto coin-swap-bg w-full p-4 lg:p-10 relative z-30   "
          >
            {/* <div className=" bg-[#16161D] rounded-[20px] p-4 sm:p-5 md:p-7 lg:p-14 w-full">
              <div className="flex justify-between  items-center w-full">
                <span></span>
                <p className="text-sm sm:text-base text-[#F2F2F5] "> Swap</p>
                <button className=" border-0">
                  <SettingIcon />
                </button>
              </div>
              <div className="bg-[#242731] rounded-lg p-[0.9px] mt-5 md:mt-7">
                <div className=" box-shadow-swap p-2 lg:p-3 flex flex-col rounded-lg bg-[#16161D]">
                  <div className="flex justify-between items-center">
                    <div className="flex gap-1 lg:gap-1.5">
                      <img src={ethhimg} alt="" className="" />
                      <div>
                        <p className="text-[10px] text-[#8D8D9B]">From</p>
                        <p className="text-base text-[#F2F2F5] flex items-center gap-3">
                          ETH{" "}
                          <span>
                            {" "}
                            <NextIcon />{" "}
                          </span>
                        </p>
                      </div>
                    </div>
                    <input
                      type="number"
                      name=""
                      id=""
                      placeholder="0.00"
                      className="border-0 outline-none text-2xl lg:text-3xl text-[#F2F2F5] placeholder:text-[#F2F2F5] text-end bg-transparent w-full"
                    />
                  </div>
                  <p className="text-end text-sm text-[#8D8D9B] mt-1">$0.00</p>
                </div>
                <div className="flex justify-center rounded-lg -mt-3">
                  <button className="border-0">
                    <img src={swap} alt="icon" className="w-fit" />
                  </button>
                </div>
                <div className="-mt-4 box-shadow-swap p-2 lg:p-3 flex flex-col rounded-lg bg-[#16161D]">
                  <div className="flex justify-between items-center">
                    <div className="flex gap-1 lg:gap-1.5">
                      <img src={ethhimg2} alt="" className="" />
                      <div>
                        <p className="text-[10px] text-[#8D8D9B]">To</p>
                        <p className="text-base text-[#8D8D9B] flex items-center gap-3">
                          Baby…
                          <span className="opacity-65 ">
                            <NextIcon />{" "}
                          </span>
                        </p>
                      </div>
                    </div>
                    <input
                      type="number"
                      name=""
                      id=""
                      placeholder="0.00"
                      className="border-0 outline-none text-2xl lg:text-3xl text-[#F2F2F5] placeholder:text-[#F2F2F5] text-end bg-transparent w-full"
                    />
                  </div>
                  <p className="text-end text-sm text-[#8D8D9B] mt-1">$0.00</p>
                </div>
              </div>
              <button className="my-5 bg-white py-4 rounded-xl text-[#242731] text-base w-full transition-all duration-300 ease-in-out hover:bg-opacity-80">
                Connect
              </button>
              <div className="border border-[#242731] rounded-xl p-2 md:p-3 bg-[rgba(255,255,255,0.00)]">
                <div className="flex justify-between">
                  <div className="w-1/3">
                    <span className="text-[12px] text-[#8D8D9B]">Price</span>
                    <p className="text-sm text-[#F2F2F5]"> $0²116</p>
                  </div>
                  <div className="w-1/3">
                    <span className="text-[12px] text-[#8D8D9B]">
                      Market Cap
                    </span>
                    <p className="text-sm text-[#F2F2F5]">$116.51 k</p>
                  </div>
                  <div className="w-1/3">
                    <span className="text-[12px] text-[#8D8D9B]">
                      24h volume
                    </span>
                    <p className="text-sm text-[#F2F2F5]">$70.24 k</p>
                  </div>
                </div>{" "}
                <button className="mt-4 bg-[#1E2129] py-3 rounded-xl text-white text-base w-full transition-all duration-300 ease-in-out hover:bg-opacity-80">
                  Go to charts
                </button>
              </div>
              <button className="mt-4 bg-[#09080A] border border-[#1E2129] py-4 rounded-xl text-white text-base w-full transition-all duration-300 ease-in-out hover:bg-opacity-80 relative">
                <img
                  src={teligrmaicon}
                  alt="icon"
                  className="absolute top-4 left-3"
                />
                Track Baby Neiro Coin
              </button>
            </div> */}
            <iframe
              width="100%"
              height="600"
              frameborder="0"
              className="rounded-xl w-set-box "
              allow="clipboard-read *; clipboard-write *; web-share *; accelerometer *; autoplay *; camera *; gyroscope *; payment *; geolocation *"
              src="https://flooz.xyz/embed/trade?swapDisabled=false&amp;swapNetwork=eth&amp;swapToTokenAddress=0xE9B8c15cB7C669c3f2679739c7835c6B911dbDDb&amp;swapLockToToken=true&amp;onRampDisabled=true&amp;onRampNetwork=eth&amp;onRampAsDefault=false&amp;onRampTokenAddress=ETH&amp;network=eth&amp;lightMode=false&amp;backgroundColor=transparent&amp;miniApp=false&amp;miniappIntent=swap"
            ></iframe>
          </div>
        </div>
        <div className="-mt-12 md:-mt-20 relative z-20">
          <div className="border-y border-black bg-[#C376FF] w-full h-3 md:h-5"></div>
          <div className="border-y border-black bg-[#EC52F9] w-full h-3 md:h-5"></div>
          <div className="border-y border-black bg-[#FFF8E7] w-full h-3 md:h-5"></div>
          <div className="border-y border-black bg-[#D481A1] w-full h-3 md:h-5"></div>
        </div>
      </div>
    </>
  );
}

export default CoinSwap;
