import React from "react";

function About() {
  return (
    <>
      <div
        data-aos="fade-up"
        data-aos-duration="1500"
        className="border-b border-[#FF93BC] "
      >

      </div>
    </>
  );
}

export default About;
