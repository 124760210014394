import React from "react";
import whatimg from "./../assets/png/what.webp";
import whatright from "./../assets/png/what-right.png";
import whatleft from "./../assets/png/what-left.png";
import coins from "./../assets/png/coins.png";
function WhatKogen() {
  return (
    <>
      <div className="relative py-16 lg:py-20  z-20">
        <div
          data-aos="fade-up"
          data-aos-duration="1500"
          className="absolute left-0  top-10  max-w-20 lg:max-w-[200px]"
        >
          <img src={whatleft} alt="img" className="w-full" />
        </div>
        <div className="relative max-w-[1340px] mx-auto px-4  sm:px-6 lg:px-8 2xl:px-10 z-20">
          <div className="flex flex-col-reverse sm:flex-row justify-center xl:justify-between gap-6 items-center">
            <div
              data-aos="fade-right"
              data-aos-duration="1500"
              className="max-w-[300px] md:max-w-[400px] lg:max-w-[550px] xl:max-w-[560px]"
            >
              <img src={whatimg} alt="what img" className="w-full" />
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="1500"
              className="sm:max-w-[280px] lg:max-w-[450px] xl:max-w-fit"
            >
              <h1 className="main-heading text-white text-4xl sm:text-5xl md:text-7xl lg:text-8xl xl:text-[163px]">
                WITH KABOSU
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="border-2 border-[#FF93BC] bg-[rgba(255,238,199,0.20)] min-h-16 lg:min-h-24 w-full -mt-20 lg:-mt-28 ">
        <div className="relative max-w-[1340px] mx-auto px-4  sm:px-6 lg:px-8 2xl:px-10  ">
          <img
            src={coins}
            alt="coin"
            className="w-full max-w-[200px] sm:max-w-[300px] lg:max-w-[400px] xl:max-w-[505px]  "
          />{" "}
        </div>
      </div>
    </>
  );
}

export default WhatKogen;
