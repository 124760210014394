import React from "react";
import heroImg from "./../assets/png/hero.png";
function Hero() {
  return (
    <>
      <div className="relative max-w-[1340px] mx-auto px-4 sm:px-6 lg:px-8 2xl:px-10 mt-40 xl:mt-52 rounded-[20px] hero-bg md:py-32 pb-0 pt-20  h-full overflow-visible">
        <div className="flex flex-col md:flex-row relative z-40 justify-center md:justify-between gap-8">
          <div
            data-aos="fade-up"
            data-aos-duration="500"
            className="md:max-w-[350px] lg:max-w-[500px] xl:max-w-[600px]"
          >
            <h1 className="main-heading text-white text-6xl md:text-7xl lg:text-9xl xl:text-[163px]">
              $KOGEN
            </h1>
            <p className=" text-lg xl:text-xl text-black md:text-white leading-[125%] pt-4">
             
Kogen, Kabosu's last friend before he died. A month before Kabosu died, Atsuko Sato made a post showing Kabosu and Kogen walking together under a cherry tree.{" "}

            </p>
                        <p className=" text-lg xl:text-xl text-black md:text-white leading-[125%] pt-2">
             
かぼすと最後に会った犬{" "}

            </p>

            <div className="flex gap-4 mt-5">
              <a
                href="#"
                className="text-[#1A1A1A] text-base lg:text-lg uppercase px-5 xl:px-6 xl:py-4 py-3 rounded-lg  btn  transition-all duration-300 ease-in-out hover:shadow-lg"
              >
                BUY $Kogen
              </a>{" "}
              <a
                href="#"
                className="text-[#1A1A1A] text-base lg:text-lg uppercase px-8 xl:px-12 xl:py-4 py-3  btn-2  transition-all duration-300 ease-in-out hover:shadow-lg"
              >
                Chart
              </a>
            </div>
          </div>
          <div className="max-w-[250px] md:max-w-[350px] xl:max-w-[380px] md:absolute md:right-0  mx-auto md:mx-0 md:-bottom-52 xl:-bottom-64 z-10 ">
            <img
              src={heroImg}
              alt="hero img"
              className="w-full animate-bounce    "
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
