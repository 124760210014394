import React from "react";
import { useState } from "react";
import { CopyIcon } from "../helper/icon";
import singleHookimg from "./../assets/png/singleHookimg.png";
function Tokenomics() {
  const [copied, setCopied] = useState(false);
  const textToCopy = "0x000000000000000000000000000000000000dead ";

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  return (
    <>
      <div
        id="Tokonomics"
        className="relative max-w-[1340px] mx-auto px-4 sm:px-6 lg:px-8 2xl:px-10 mt-10 md:mt-16 xl:mt-24 "
      >
        <div className="border-2 border-black rounded-[20px_20px_7px_7px] bg-[#FF93BC]">
          <div className="tokenomics-bg">
            <h1
              data-aos="zoom-out"
              data-aos-duration="1500"
              className="main-heading text-white text-4xl sm:text-5xl md:text-7xl lg:text-8xl xl:text-[163px] py-6 md:py-12 text-center uppercase font-normal"
            >
              Tokenomics
            </h1>
          </div>{" "}
          <div className="flex items-center justify-center py-4 md:py-6 xl:py-8 gap-2 lg:gap-4">
            <p className="lg:text-3xl xl:text-[35px]  text-black text-sm sm:text-base md:text-xl text-ellipsis overflow-hidden  truncat">
              {" "}
              CA : 0x000000000000000000000000000000000000dead 
            </p>{" "}
            <button className="text-base" onClick={handleCopy}>
              {copied ? "Copied!" : <CopyIcon />}
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-5  my-5">
          <div className="flex  md:flex-row flex-col items-center justify-center">
            <div
              data-aos="fade-right"
              data-aos-duration="1500"
              className="md:w-1/2 w-full card-box py-5 text-center md:-mr-5"
            >
              <p className="text-lg sm:text-xl lg:text-[22px] text-[#383535]">
                Ticker
              </p>
              <h4 className="text-2xl sm:text-3xl lg:text-[40px] text-black">
                {" "}
                $KOGEN
              </h4>
            </div>
            <div className="">
              <img
                src={singleHookimg}
                alt="img"
                className="relative z-10 rotate-90 md:rotate-0 "
              />
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="1500"
              className="md:w-1/2 w-full card-box py-5 text-center md:-ml-5"
            >
              <p className="text-lg sm:text-xl lg:text-[22px] text-[#383535]">
                Liquidity{" "}
              </p>
              <h4 className="text-2xl sm:text-3xl lg:text-[40px] text-black">
                100% Burned
              </h4>
            </div>
          </div>
          <div className="flex  md:flex-row flex-col items-center justify-center">
            <div
              data-aos="fade-right"
              data-aos-duration="2000"
              className="md:w-1/2 w-full card-box py-5 text-center md:-mr-5"
            >
              <p className="text-lg sm:text-xl lg:text-[22px] text-[#383535]">
                Total Supply{" "}
              </p>
              <h4 className="text-2xl sm:text-3xl lg:text-[40px] text-black">
                Ethereum
              </h4>
            </div>
            <div className="">
              <img
                src={singleHookimg}
                alt="img"
                className="relative z-10 rotate-90 md:rotate-0 "
              />
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="2000"
              className="md:w-1/2 w-full card-box py-5 text-center md:-ml-5"
            >
              <p className="text-lg sm:text-xl lg:text-[22px] text-[#383535]">
                Total Supply
              </p>
              <h4 className="text-2xl sm:text-3xl lg:text-[40px] text-black">
                100.000.000
              </h4>
            </div>
          </div>{" "}
          <div className="flex  md:flex-row flex-col items-center justify-center">
            <div
              data-aos="fade-right"
              data-aos-duration="3000"
              className="md:w-1/2 w-full card-box py-5 text-center md:-mr-5"
            >
              <p className="text-lg sm:text-xl lg:text-[22px] text-[#383535]">
                Texes
              </p>
              <h4 className="text-2xl sm:text-3xl lg:text-[40px] text-black">
                No tax 0/0{" "}
              </h4>
            </div>
            <div className="">
              <img
                src={singleHookimg}
                alt="img"
                className="relative z-10 rotate-90 md:rotate-0 "
              />
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="3000"
              className="md:w-1/2 w-full card-box py-5 text-center md:-ml-5"
            >
              <p className="text-lg sm:text-xl lg:text-[22px] text-[#383535]">
                Contract Ownership
              </p>
              <h4 className="text-2xl sm:text-3xl lg:text-[40px] text-black">
                Renounced
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tokenomics;
