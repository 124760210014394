import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./App.css";
import Header from "./components/common/Header";
import Hero from "./components/home/Hero";
import MediaBox from "./components/home/MediaBox";
import AddressCopy from "./components/home/AddressCopy";
import WhatKogen from "./components/home/WhatKogen";
import About from "./components/home/About";
import HowToBuy from "./components/home/HowToBuy";
import Tokenomics from "./components/home/Tokenomics";
import Footer from "./components/common/Footer";
import Slider from "./components/common/Slider";
import CoinSwap from "./components/home/CoinSwap";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: true, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the
    });
  }, []);
  return (
    <>
      <div className="overflow-x-hidden ">
        <Header />
        <div className="">
          <Hero />
          <MediaBox />
        </div>
        {/* <AddressCopy /> */}
        <WhatKogen />
        <About />
        <HowToBuy />
        <CoinSwap />
        <Slider />
        <Tokenomics />
        <Footer />
      </div>
    </>
  );
}

export default App;
