import React from "react";
import { mediaLinks } from "../helper/Helper";
import hangerimg from "./../assets/png/hanger.png";
function MediaBox() {
  return (
    <>
      <div
        data-aos="fade-up"
        data-aos-duration="1500"
        className="relative max-w-[1340px] mx-auto px-4 sm:px-6 lg:px-8 2xl:px-10 mt-6    z-[50] "
      >
        <div className="max-w-[950px] media-box py-6 mx-auto  relative z-30 flex flex-wrap gap-4 px-4 items-center  justify-around">
          <div className="hidden sm:block sm:absolute -top-16 max-w-[57px]">
            <img src={hangerimg} alt="img" className="w-full" />
          </div>
          {mediaLinks.map((item, index) => (
            <a
              key={index}
              href={item.link}
              className="transition-all duration-300 ease-in-out border-2 border-[#484848] hover:border-black w-14 h-14 lg:w-[70px] lg:h-[70px]  rounded-[20px] bg-[#F95292] group flex flex-col justify-center items-center"
            >
              {item.icon}{" "}
            </a>
          ))}
        </div>
      </div>
      <div className="mb-5 -mt-6 md:-mt-10">
        <div className="border-y border-black bg-[#C376FF] w-full h-3 md:h-5"></div>
        <div className="border-y border-black bg-[#EC52F9] w-full h-3 md:h-5"></div>
        <div className="border-y border-black bg-[#FFF8E7] w-full h-3 md:h-5"></div>
        <div className="border-y border-black bg-[#D481A1] w-full h-3 md:h-5"></div>
      </div>
    </>
  );
}

export default MediaBox;
