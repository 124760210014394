import {
  BarIcon,
  MIcon,
  MoIcon,
  SandIcon,
  TeligramIcon,
  TwitterIcon,
  UniIcon,
} from "./icon";
import img1 from "./../assets/svg/img1.svg";
import img2 from "./../assets/svg/img2.svg";
export const headerLinks = [
  { name: "Home", link: "/" },
  { name: "How to Buy", link: "#HowtoBuy" },
  { name: "Tokonomics", link: "#Tokonomics" },
];
export const mediaLinks = [
  { icon: <TeligramIcon />, link: "https://t.me/kogeneth" },
  { icon: <TwitterIcon />, link: "https://x.com/kogencoin" },
  { icon: <BarIcon />, link: "/" },
  { icon: <SandIcon />, link: "/" },
  { icon: <MoIcon />, link: "/" },
  { icon: <UniIcon />, link: "/" },
  { icon: <MIcon />, link: "/" },
];
export const sliderData = [
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
  { path: img1 },
  { path: img2 },
];
