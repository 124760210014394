import React from "react";
import { sliderData } from "../helper/Helper";

function Slider() {
  return (
    <>
      <div className="bg-[rgba(255,147,188,0.50)] w-full py-5 md:py-6 border-y-2 border-black ">
        <marquee behavior="" direction="left">
          <div className="flex gap-4 sm:gap-6 xl:gap-8 items-center">
            {sliderData.map((item, index) => (
              <img key={index} src={item.path} alt="img" className="" />
            ))}
          </div>
        </marquee>
      </div>
    </>
  );
}

export default Slider;
