import React from "react";
import metaimg from "./../assets/svg/meta.svg";
import ethimg from "./../assets/svg/eth.svg";
import kogenimg from "./../assets/svg/kogen.svg";
import walletimg from "./../assets/svg/wallet.svg";
import hooksimg from "./../assets/png/hooks.png";
function HowToBuy() {
  return (
    <>
      <div id="HowtoBuy" className="my-16 lg:my-20 xl:my-28">
        <div className="relative max-w-[1340px] mx-auto px-4 sm:px-6 lg:px-8 2xl:px-10">
          <div className=" by-bg">
            <h1
              data-aos="zoom-out"
              data-aos-duration="1500"
              className="main-heading text-white text-4xl sm:text-5xl md:text-7xl lg:text-8xl xl:text-[163px] py-6 md:py-12 text-center uppercase font-normal"
            >
              how to buy
            </h1>
          </div>
          <div className="bg-[#FD6AA2] p-4 lg:p-6 xl:p-12 border-2 border-[#000] buy-2 rounded-lg  relative -z-[10]  -mt-2">
            <div className="flex sm:flex-row flex-col  items-center bg-[#F95292] border-2 border-[#000] rounded-lg shadow-[2px_2px_0px_0px] shadow-[#000000bf] mt-3 xl:mt-4  ">
              <div className="px-1 md:px-6 xl:px-8 py-1 mt-3 sm:mt-0 md:py-8   border-[#454444] border-2 rounded-lg  relative flex flex-col  justify-center  items-center  z-20">
                <img
                  src={hooksimg}
                  alt="hook"
                  className="w-full max-w-7 md:max-w-10 lg:max-w-12 absolute hidden sm:block sm:-bottom-14 md:-bottom-11 lg:-bottom-14 z-30  "
                />
                <div className="sm:w-[120px] w-16 h-16 sm:h-[120px] rounded-full border border-[#FED8BF] p-1 md:p-1.5 lg:p-2 xl:p-2.5 backdrop-blur-[6px] bg-[#FE86B3] ">
                  <img src={metaimg} alt="meta" className="w-full" />
                </div>
              </div>
              <div
                data-aos="fade-left"
                data-aos-duration="1500"
                className="flex flex-col gap-1 lg:gap-2 px-4 md:px-6 xl:px-8 py-6 md:py-8 max-w-[890px]"
              >
                <h2 className="sub-heading text-2xl md:text-3xl lg:text-4xl xl:text-5xl capitalize ">
                  DOWNLOAD metamask EXTENSION
                </h2>{" "}
                <p className="text-base sm:text-lg xl:text-xl text-black  ">
                  Download Metamask on the appstore, or,
                  alternatively if you're on desktop download and install the
                  browser extension.
                </p>
              </div>
            </div>{" "}
            <div className="flex sm:flex-row flex-col-reverse  items-center bg-[#F95292] border-2 border-[#000] rounded-lg shadow-[2px_2px_0px_0px] shadow-[#000000bf] mt-4 xl:mt-6 rotate-[0.5deg]  ">
              <div
                data-aos="fade-right"
                data-aos-duration="1500"
                className="flex flex-col gap-1 lg:gap-2 px-4 md:px-6 xl:px-8 py-6 md:py-8  "
              >
                <h2 className="sub-heading text-2xl md:text-3xl lg:text-4xl xl:text-5xl capitalize ">
                  secure your eth
                </h2>{" "}
                <p className="text-base sm:text-lg xl:text-xl text-black  ">
                  Now all you've got to do is buy your eth in the METAMASK APP,
                  or, alternatively you can use an exchange and deposit using
                  your wallet address.
                </p>
              </div>
              <div className="px-1 md:px-6 xl:px-8 py-1 mt-3 sm:mt-0 md:py-8   border-[#454444] border-2 rounded-lg   relative flex flex-col  justify-center  items-center">
                {" "}
                <img
                  src={hooksimg}
                  alt="hook"
                  className="w-full max-w-7 md:max-w-10 lg:max-w-12 absolute hidden sm:block sm:-bottom-14 md:-bottom-11 lg:-bottom-14 z-20  "
                />
                <div className="sm:w-[120px] w-16 h-16 sm:h-[120px] rounded-full border border-[#FED8BF] p-1 md:p-1.5 lg:p-2 xl:p-2.5 backdrop-blur-[6px]  bg-[#FE86B3]">
                  <img src={ethimg} alt="meta" className="w-full" />
                </div>
              </div>
            </div>{" "}
            <div className="flex sm:flex-row flex-col  items-center bg-[#F95292] border-2 border-[#000] rounded-lg shadow-[2px_2px_0px_0px] shadow-[#000000bf] mt-3 xl:mt-4  ">
              <div className="px-1 md:px-6 xl:px-8 py-1 mt-3 sm:mt-0 md:py-8   border-[#454444] border-2 rounded-lg   relative flex flex-col  justify-center  items-center">
                {" "}
                <img
                  src={hooksimg}
                  alt="hook"
                  className="w-full max-w-7 md:max-w-10 lg:max-w-12 absolute hidden sm:block sm:-bottom-14 md:-bottom-11 lg:-bottom-14 z-10   "
                />
                <div className="sm:w-[120px] w-16 h-16 sm:h-[120px] rounded-full border border-[#FED8BF] p-1 md:p-1.5 lg:p-2 xl:p-2.5 backdrop-blur-[6px] bg-[#FE86B3]">
                  <img src={kogenimg} alt="meta" className="w-full" />
                </div>
              </div>
              <div
                data-aos="fade-left"
                data-aos-duration="1500"
                className="flex flex-col gap-1 lg:gap-2 px-4 md:px-6 xl:px-8 py-6 md:py-8 max-w-[890px]"
              >
                <h2 className="sub-heading text-2xl md:text-3xl lg:text-4xl xl:text-5xl capitalize ">
                  Buy some $kogen
                </h2>{" "}
                <p className="text-base sm:text-lg xl:text-xl text-black  ">
                  Head to Uniswap, paste the $KOGEN Contract Address to swap
                  your eth, then confirm the transaction to complete the swap.
                </p>
              </div>
            </div>{" "}
            <div className="flex sm:flex-row flex-col-reverse  items-center bg-[#F95292] border-2 border-[#000] rounded-lg shadow-[2px_2px_0px_0px] shadow-[#000000bf] mt-4 xl:mt-6 rotate-[0.5deg]">
              <div
                data-aos="fade-right"
                data-aos-duration="1500"
                className="flex flex-col gap-1 lg:gap-2 px-4 md:px-6 xl:px-8 py-6 md:py-8 "
              >
                <h2 className="sub-heading text-2xl md:text-3xl lg:text-4xl xl:text-5xl capitalize ">
                  Add to your wallet
                </h2>{" "}
                <p className="text-base sm:text-lg xl:text-xl text-black  ">
                  You're all set! Welcome aboard the next rocket to the moon,
                  just import the contract address to view you're holdings and
                  you can track your earnings.
                </p>
              </div>
              <div className="px-1 md:px-6 xl:px-8 py-1 mt-3 sm:mt-0 md:py-8   border-[#454444] border-2 rounded-lg  ">
                <div className="sm:w-[120px] w-16 h-16 sm:h-[120px] rounded-full border border-[#FED8BF] p-1 md:p-1.5 lg:p-2 xl:p-2.5 backdrop-blur-[6px] bg-[#FE86B3]">
                  <img src={walletimg} alt="meta" className="w-full" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToBuy;
