import React, { useEffect, useState } from "react";
import { headerLinks } from "../helper/Helper";
import { HamburgerIcon } from "../helper/icon";
import MobileHeader from "./MobileHeader";
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const body = document.body;
    const html = document.documentElement;
    if (isOpen) {
      body.style.overflow = "hidden";
      html.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
      html.style.overflow = "auto";
    }
    return () => {
      body.style.overflow = "auto";
      html.style.overflow = "auto";
    };
  }, [isOpen]);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div
        className={`fixed top-0 w-full z-50 ${
          isScrolled
            ? ` bg-[#F95292] transition-all duration-300 ease-in-out `
            : ``
        }`}
      >
        <div
          data-aos="fade-down"
          data-aos-duration="2000"
          className="relative max-w-[1340px] mx-auto px-4 sm:px-6 lg:px-8 2xl:px-10 py-3 md:py-5 z-10  nav-shadow sm:rounded-[20px] sm:mt-6 "
        >
          <div className="flex justify-between items-center">
            <a
              href="/"
              className="logo text-white uppercase text-3xl lg:text-[40px]"
            >
              Kogen
            </a>
            <div className="items-center gap-x-4 lg:gap-x-6 xl:gap-x-8 2xl:gap-x-10 hidden lg:flex">
              {headerLinks.map((item, index) => (
                <a
                  key={index}
                  href={item.link}
                  className="text-[#000] text-base transition-all duration-300 ease-in-out hover:text-black"
                >
                  {item.name}
                </a>
              ))}
              <a
                href="#"
                className="text-[#1A1A1A] text-base lg:text-lg uppercase px-5 xl:px-6 xl:py-4 py-3 rounded-lg btn transition-all duration-300 ease-in-out hover:shadow-lg"
              >
                BUY $Kogen
              </a>
            </div>

            <button className="lg:hidden" onClick={toggleOpen}>
              <HamburgerIcon />
            </button>
          </div>
        </div>
      </div>

      <div
        className={`fixed top-0 left-0 w-full h-full bg-[#EC52F9] lg:bg-transparent transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-[70]`}
      >
        <MobileHeader toggleOpen={toggleOpen} />
      </div>
    </>
  );
};

export default Header;
